import Swiper from 'swiper'
import { Pagination, Navigation } from 'swiper/modules'

export const initSliderForListNews = () => {
  const swiper = new Swiper('.list-news .swiper', {
    watchSlidesProgress: true,
    modules: [Pagination, Navigation],
    pagination: {
      clickable: false,
      el: '.list-news .custom-slider-pagination',
      bulletClass: 'custom-slider-pagination-bullet',
      bulletActiveClass: 'custom-slider-pagination-bullet-active',
    },
    navigation: {
      prevEl: '.list-news .custom-arrow-navigation-left',
      nextEl: '.list-news .custom-arrow-navigation-right',
      disabledClass: 'custom-arrow-navigation-disabled',
    },
    breakpoints: {
      320: {
        spaceBetween: 8,
        slidesPerView: 2,
      },
      960: {
        spaceBetween: 16,
        slidesPerView: 4,
      },
    },
  })

  return swiper
}
