'use client'

import { useEffect } from 'react'
import { ArrowNavigationLeft, ArrowNavigationRight } from '../SliderUtils/ArrowNavigation'
import { MainNewsItem } from '../../../domain/mainNews.types'
import { initSliderForListNews } from '../../utils/ListNews.utils'
import { escapeHtml } from '../../utils/escape.utils'
import { buildFullUrlMedia } from '../../../core/hook/utils'
import Animate from '../Animate/animate'
import { useTranslation } from '../../context/ConfigContext'
import Image from '../Image/Image'
import Link from '../Link/Link'

type NewsItemProps = {
  newsItem: MainNewsItem
}

const NewsItem = ({ newsItem }: NewsItemProps) => {
  const { title, description, image, url } = newsItem

  return (
    <article aria-label={title && escapeHtml(title)} className='news-item'>
      <Link href={url ?? ''}>
        <picture>
          <Image
            src={buildFullUrlMedia(image ?? '')}
            alt={title ? escapeHtml(title) : ''}
            width={400}
            height={400}
            priority={true}
          />
        </picture>
        {title && <h3>{escapeHtml(title)}</h3>}
        {description && <div>{escapeHtml(description)}</div>}
      </Link>
    </article>
  )
}

type ListNewsProps = {
  news: MainNewsItem[] | null
}

const ListNews = ({ news }: ListNewsProps) => {
  const t = useTranslation()
  useEffect(() => {
    const swiper = initSliderForListNews()

    return () => {
      swiper?.destroy?.()
    }
  }, [])

  return (
    <section className='list-news'>
      <Animate>
        <h2 className='title-h2'>{t('home-slider-actu-title', {}, true)}</h2>
      </Animate>
      <Animate>
        <div className='swiper'>
          <div className='swiper-wrapper list-news-swiper'>
            {news?.map((newsItem) => (
              <div className='swiper-slide' key={newsItem.id}>
                <NewsItem newsItem={newsItem} />
              </div>
            ))}
          </div>
        </div>
      </Animate>

      <Animate>
        <div className='navigation-container'>
          <div className='bloc-navigation bloc-navigation-left'>
            <ArrowNavigationLeft />
          </div>

          <div className='explorer-animation bullet'>
            <div className='explorer-animation-bullet'></div>
            <div className='explorer-animation-bullet'></div>
            <div className='explorer-animation-bullet'></div>
          </div>

          <div className='bloc-navigation bloc-navigation-right'>
            <ArrowNavigationRight />
          </div>
        </div>
      </Animate>
    </section>
  )
}

export default ListNews
