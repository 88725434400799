'use client'

import { useEffect } from 'react'

const AnimationInView = () => {
  useEffect(() => {
    const element = document.querySelector(
      '.cms-block-noheadless .Block-02 .Item-02',
    ) as HTMLElement

    if (!element) {
      return
    }

    element.classList.add('legacy-animate')

    const elementHeight = element.clientHeight
    document.addEventListener('scroll', animate)
    inView()

    function inView() {
      const windowHeight = window.innerHeight
      const scrollY = window.scrollY || window.pageYOffset
      const scrollPosition = scrollY + windowHeight
      const elementPosition = element.getBoundingClientRect().top + scrollY + elementHeight
      if (scrollPosition > elementPosition) {
        return true
      }
      return false
    }

    function animate() {
      if (inView()) {
        element.classList.add('slide-in')
      }
    }
  }, [])

  return null
}

export default AnimationInView
