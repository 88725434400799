'use client'

import { ReactElement, FC, useRef, useEffect, useState } from 'react'
import { useIsMobileOrTablet } from '../../utils/screen.utils'
import clsx from 'clsx'

type layoutProps = {
  children: ReactElement
  className?: string | ''
}

const Animate: FC<layoutProps> = ({ children, className }) => {
  const ref = useRef<HTMLDivElement>(null)
  const isTablet = useIsMobileOrTablet()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (ref.current) {
            setVisible(true)
            observer.unobserve(entry.target)
          }
        }
      },
      { rootMargin: isTablet ? '0px' : '-200px' },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => observer.disconnect()
  }, [isTablet])

  useEffect(() => {
    if (ref.current && visible) {
      ref.current
        .querySelectorAll(
          '.Item-09 .fat-title, .Block-10 .fat-title, .swiper-slide, .title-h2, p, figure, .media, .wrapper-push-item, .card, .services-content article, .title-h5, .benefit ul li, .list-selection-wrapper, .push-price, .buttons',
        )
        .forEach((el) => {
          el.classList.add('slide-in')
        })
    }
  }, [visible, isTablet])

  return (
    <div ref={ref} className={clsx('animate', className)}>
      {children}
    </div>
  )
}

export default Animate
