import * as cheerio from 'cheerio';
import { CmsBlock } from '../../../queries/CmsBlocksContent.gql'
import AnimationVideoOnDetailBlogItem from '../BlogCmsComponents/Detail/AnimationVideoOnDetailBlogItem'
import CmsBlockClient from './CmsBlock.client'

export function CmsBlockContent({
  item,
  className,
  shouldRemoveParentClass,
}: {
  item: CmsBlock
  className?: string
  shouldRemoveParentClass?: boolean
}) {
  const { content } = item
  const htmlContent:string = filterContent(content)

  const targetClass = 'cms-block-1'

  return (
    <>
      {htmlContent && (
        <>
          <div
            className={`${className} ${shouldRemoveParentClass ? 'parent-to-remove' : ''}`}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />

          {/* FOR VIDEO CONTROL & SLIDER */}
          <CmsBlockClient
            className={className ? className : targetClass}
            shouldRemoveParentClass={shouldRemoveParentClass}
          />

          {/* FOR THE VIDEO AUTOPLAY */}
          <AnimationVideoOnDetailBlogItem />
        </>
      )}
    </>
  )
}

export const filterContent = (content) => {
  // Handle contrast for banner without text
  content = addClassIfBannerSpansEmpty(content);
  content = addClassIfHasCaption(content);

  return content;
}

const addClassIfBannerSpansEmpty = (rawHTML) => {
  // Load the raw HTML into cheerio
  const $ = cheerio.load(rawHTML);

  // Find all h2 elements
  $('h2.title').each(function () {
      const spanLeft = $(this).find('.align-l');
      const spanRight = $(this).find('.align-r');

      // Check if both spans are empty
      if ($(this).text().trim() == "" || (spanLeft.text().trim() === "" && spanRight.text().trim() === "")) {
          // Add the class to the container if both spans are empty
          $(this).closest('.Block').addClass('no-contrast');
      }
  });

  // Return the modified HTML
  return $.html();
}

const addClassIfHasCaption = (rawHTML) => {
  // Load the raw HTML into cheerio
  const $ = cheerio.load(rawHTML);

  // Find all h2 elements
  $('figure').each(function () {
    const figure = $(this);
      const figcaption = figure.find('figcaption');

      if(figcaption){
        const h2 = figcaption.find('h2');
        const p = figcaption.find('p');

        if(h2.text().trim() != "" || p.text().trim() != ""){
          figure.addClass('contrast')
        }
      }
  });

  // Return the modified HTML
  return $.html();
}

export default CmsBlockContent
