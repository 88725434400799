'use client'

import { ReactElement, useEffect, useState } from 'react'
import { SelectionItem } from '../../../domain/selectionItem'
import ListSelectionItem from '../ListSelectionItem/ListSelectionItem'
import { useQuery } from '../../../core/hook/useQuery'
import { ProductClientDocument } from '../../../queries/ProductClient.gql'

type ListSelectionProps = {
  items: SelectionItem[]
  title?: ReactElement<'h2' | 'h3' | 'h4' | 'h5' | 'h6'>
  parentClassName?: string
  centerInsufficientSlides?: boolean
  hideColorVariations?: boolean
  disableSwiperOfImages?: boolean
  isHome?: boolean
}

const ListSelectionGrid = ({
  items,
  title,
  hideColorVariations = false,
  disableSwiperOfImages = false,
  isHome = false,
}: ListSelectionProps) => {
  const { data } = useQuery(ProductClientDocument, {
    variables: {
      filters: { url_key: { in: items.map(
            (item) => item.urlKey
          ) } },
      pageSize: items.length,
    },
    fetchPolicy: "no-cache"
  })
  const [itemsData, setItemsData] = useState<SelectionItem[]>(items)

  useEffect(() => {
    if (!data?.products?.items) {
      setItemsData(itemsData.map((item) => ({
          ...item,
          stockStatus: "Loading",
        })))
    } else {
      setItemsData(
        itemsData.map((item) => {
          const product = data.products?.items!.find(
            (product) => product!.id === item.id
          )

          return {
            ...item,
            stockStatus: product?.stock_status ?? item?.stockStatus,
          }
        })
      )
    }
  }, [data])

  if (itemsData.length < 1) {
    return null
  }

  return (
    <section className='list-selection list-selection-grid'>
      {title}

      <div className='list-selection-wrapper list-selection-grid-wrapper'>
        {itemsData.map((item, i) => (
          <div className='item' key={`${item.name}`}>
            <ListSelectionItem
              item={item}
              isHome={isHome}
              className='list-selection'
              hideColorVariations={hideColorVariations}
              keepOnlyOneImage={disableSwiperOfImages}
              position={i + 1}
            />
          </div>
        ))}
      </div>
    </section>
  )
}

export default ListSelectionGrid
