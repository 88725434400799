'use client'

import { useEffect, useState } from 'react'
import Swiper from 'swiper'
import { EffectFade, Navigation } from 'swiper/modules'
import { CustomCmsSlider, CustomCmsSliderItem } from '../../../domain/cmsSlider.types'
import { ArrowNavigationLeft, ArrowNavigationRight } from '../SliderUtils/ArrowNavigation'
import { useTranslation } from '../../context/ConfigContext'
import Image from '../Image/Image'
import { handleError } from '../../../core/hook/utils'
import { useIsMobileOrTablet } from '../../utils/screen.utils'

type HeaderMediaProps = {
  contrast?: boolean
  sliderItem: CustomCmsSliderItem
  page?: {
    page: string
  }
}

const HeaderMedia = ({ page, sliderItem, contrast }: HeaderMediaProps) => {
  const t = useTranslation()
  const isTablet = useIsMobileOrTablet()

  const renderVideoPoster = () => {
    let poster = sliderItem.url

    if (typeof window !== 'undefined' && window.matchMedia('(max-width: 959px)').matches) {
      if (sliderItem.mobileUrl) {
        poster = `${sliderItem.mobileUrl}`
      }
    }

    return poster
  }

  const text = sliderItem.content ?? ''

  const [isExpanded, setIsExpanded] = useState(false)
  const truncatedText = text.substring(0, text.length / 2)

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  if (!page) {
    return null
  }

  return (
    <div className={`header-media ${contrast ? 'contrast' : ''}`}>

      <div className='header-media-source'>
        {sliderItem.video_path && !isTablet && (
          <div className='video contrast '>
            <video loop playsInline muted aria-hidden="true" autoPlay poster={renderVideoPoster()}>
              <source src={sliderItem.video_path} type="video/mp4"  />
            </video>
          </div>
        )}

        {sliderItem.mobile_video_path && isTablet  && (
          <div className='video contrast'>
            <video loop playsInline muted aria-hidden="true" autoPlay poster={renderVideoPoster()}>
              {sliderItem.mobile_video_path &&
                <source src={sliderItem.mobile_video_path} type="video/mp4"  />
              }
            </video>
          </div>
        )}

        {sliderItem.youtube_code && (
          <div className='video contrast'>
            <iframe
              src={`https://www.youtube.com/embed/${sliderItem.youtube_code}?autoplay=1&loop=1&playlist=${sliderItem.youtube_code}&mute=1&showinfo=0&controls=0&rel=0&modestbranding=1`}
              title='YouTube video player'
              allow='autoplay loop mute'
            />
          </div>
        )}

        {!sliderItem.video_path && !isTablet && !sliderItem.youtube_code ? (
          <picture className="contrast">
            <Image src={sliderItem.url} size="m" alt="" priority={true} />
          </picture>
        ) : null}

        {!sliderItem.mobile_video_path && isTablet && !sliderItem.youtube_code ? (
          <picture className='contrast'>
            <Image src={sliderItem.mobileUrl} size='s' alt='' priority={true} />
          </picture>
        ) : null}
      </div>

      <div className='header-media-content'>
        {page.page === 'home' ? (
          <div dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <>
            {isTablet ? (
              <div className='mobile'>
                {sliderItem.content && (
                  <>
                    {isExpanded ? (
                      <div className='container-header'>
                        <p dangerouslySetInnerHTML={{ __html: text }} />
                      </div>
                    ) : (
                      <div className='container-header'>
                        <p dangerouslySetInnerHTML={{ __html: `${truncatedText}... ` }} />
                      </div>
                    )}

                    {text.length > truncatedText.length && (
                      <button className='btn-see-more' onClick={handleToggle} type='button'>
                        {isExpanded
                          ? t('catalog-category-header-see-less', {}, true)
                          : t('catalog-category-header-see-more', {}, true)}
                      </button>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className='desktop'>
                <div className='descr'>
                  <p dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

type HeaderSliderProps = {
  slider: CustomCmsSlider | null
  contrast?: boolean
  page?: {
    page: string
  }
}

const HeaderSlider = ({ page, slider, contrast = false }: HeaderSliderProps) => {
  useEffect(() => {
    const swiper = new Swiper('.header-slider .header-slider-swiper', {
      watchSlidesProgress: true,
      modules: [Navigation, EffectFade],
      slidesPerView: 1,
      allowTouchMove: true,
      loop: true,
      effect: 'fade',
      speed: 500,
      navigation: {
        prevEl: '.custom-arrow-navigation-left',
        nextEl: '.custom-arrow-navigation-right',
        disabledClass: 'custom-arrow-navigation-disabled',
      },
    })

    return () => {
      try {
        swiper.destroy?.()
      } catch (error) {
        // TODO workaround
        handleError(error, false)
      }
    }
  }, [slider])

  return (
    <div className={`header-slider ${contrast ? 'contrast' : ''}`}>
      <div className='swiper header-slider-swiper'>
        <div className='swiper-wrapper header-slider-swiper-wrapper'>
          {slider?.sliderItems.map((sliderItem) => {
            if (sliderItem.url && sliderItem.mobileUrl) {
              return (
                <div
                  className='swiper-slide header-slider-swiper-slide'
                  key={`header-slider-${sliderItem.url}`}>
                  <HeaderMedia sliderItem={sliderItem} contrast={contrast} page={page} />
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>
      {slider?.sliderItems.length !== undefined && slider?.sliderItems.length > 1 && (
        <div className='header-slider-arrows'>
          <ArrowNavigationLeft />
          <ArrowNavigationRight />
        </div>
      )}
    </div>
  )
}

export default HeaderSlider
